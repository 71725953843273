/* eslint-disable */
import React from 'react';
import { useEffect, useState, Fragment } from "react";
import { useParams } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './Item.css'
import { timeDifference, fetchById } from "./utils/Common.jsx";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import { MagnifyingGlass } from 'react-loader-spinner'
import { Helmet } from 'react-helmet';


let DefaultIcon = L.divIcon({ html: '<div class="map-custom-icon"></div>', className: 'map-custom-icon' });

const buttonStyle = {
    width: "30px",
    background: 'none',
    border: '0px'
};

const properties = {
    prevArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#000"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" /></svg></button>,
    nextArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#000"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z" /></svg></button>,
    indicators: true,
    autoplay: false,
    transitionDuration: 300
}

const Item = () => {
    const { id } = useParams();

    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchItem = async () => {
            const data = await fetchById(id, "id address priceUsd priceByn rooms lastUp avatar isOwner photos isActive latitude longitude info url");
            setDetails({ id, ...data })
            setLoading(false)
        };
        fetchItem()
    }, [id]);

    return (<div className='item-wrapper'>{loading ? (<div className='loading-wrapper'><MagnifyingGlass
        visible={true}
        height="100"
        width="100"
        ariaLabel="magnifying-glass-loading"
        wrapperClass="magnifying-glass-wrapper"
        glassColor="#fff"
        color="#669bbc"
    /></div>) : details && details.isActive ? (<Data details={details}></Data>) : (
        <p className='not-found'>

            <Helmet>
                <meta name="robots" content="noindex,nofollow" />
                <title>Объявдение не найдено или не актуально</title>
            </Helmet>Объявление не найдено или не актуально</p>
    )}
    </div>
    );
};




const Data = ({ details }) => {

    const handleClick = () => { window.open('https://t.me/domanetby', '_blank', 'noopener,noreferrer,nofollow'); };
    return (
        <div className="item-page">
            {details.photos?.length > 0 &&
                <Slide {...properties}>
                    {details.photos.map((each, index) => (
                        <div key={index} className="item-image-box">
                            <img loading="lazy" className="item-image" alt="Slide" src={each} />
                        </div>
                    ))}
                </Slide>
            }

            <div className="main-info">
                <div className="main-info-item last-up">{timeDifference(details.lastUp)}</div>
                <div className="main-info-item price">{details.priceUsd <= 0 ? `Договорная` : `${details.priceUsd}$ / ${details.priceByn} р.`}</div>
                <div className="main-info-item rooms">{details.rooms === 0 ? 'Комната' : `${details.rooms}-комнатная квартира`}</div>
                <div className="main-info-item owner">{details.isOwner ? 'Собственник' : 'Агентство'}</div>
                <a className="main-info-item contact" href={details.url} onClick={handleClick}>Связаться</a>
                <a className="main-info-item telegram" href="https://t.me/domanetby">Телеграм-канал</a>
                <a className="main-info-item telegram" href="https://t.me/infoflatbot">Телеграм-бот</a>
                <div className="main-info-item info">{details.info}</div>
                <div className="main-info-item address"><div className="address-value">{details.address}</div>
                    <MapContainer
                        className="item-map"
                        center={[details.latitude, details.longitude]}
                        zoom={13}
                        maxZoom={18}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <Fragment key={1}>
                            <Marker
                                position={[details.latitude, details.longitude]}
                                key={1}
                                icon={DefaultIcon}
                            ></Marker>
                        </Fragment></MapContainer>
                </div>
            </div>
            <Helmet>
                <title>{details.address}</title>
                <description>{details.info}</description>
            </Helmet>
        </div>

    )
}

export default Item;